<template>
  <div class="login">
    <van-form @submit="onSubmit" class="login-form">
      <div style="text-align: center;padding: 30px 0;"><van-image fit="cover" round src="https://img01.yzcdn.cn/vant/cat.jpeg" class="login-image"/></div>
      <van-field
          v-model.trim="form.username"
          label="账号"
          placeholder="请输入账号"
          clearable
          :rules="[{ required: true, message: '请填写账号' }]"
      />
      <van-field
          v-model.trim="form.password"
          center
          clearable
          label="密码"
          :maxlength="6"
          type="password"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 20px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { login } from '@/api/login'

export default {
  data() {
    return {
      form: {
       
		username: '18736794154',
        password: 123456,
      }
    }
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      login(this.form).then((res) => {
        this.$store.commit('SET_TOKEN', res.data.token)
        this.$store.commit('SET_IS_LOGIN', true)
        if (this.$route.query.redirect) window.location.href = this.$route.query.redirect
        else window.location.href = '/'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  min-height: calc(100vh);
  background: white;
  .login-form {
    background: white;
    padding: 0 20px;
  }
  .login-image {
    width: 80px;
    height: 80px;
  }
}
</style>
